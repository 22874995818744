import db from '../components/firebaseInit'
import firebase from 'firebase'

const mixin_UserRoles = {
    data() {
        return {
            user_roles_list: [],
            loading_user: false,
            firstLoad: true,
        }
    },
    created() {
        //Verification
        //Get the user id of the current user
        var user_id = firebase.auth().currentUser.uid
        this.loading_user = true,
            this.firstLoad = true
        // Get current user's roles
        db.collection('users').where('uid', '==', user_id).get().then(querySnapshot => {
            querySnapshot.forEach(doc => {
                const user_roles = doc.data().roles
                // Send user roles to array
                this.user_roles_list.push(user_roles)
                this.firstLoad = false
                this.loading_user = false
            })
        })
    }
}

export default mixin_UserRoles