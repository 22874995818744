<template>
  <v-container>
    <v-row class="mb-8">
      <v-col lg="12">
        <v-card-title>
          <h2>Product Variants</h2>
          <v-btn @click="$router.push('/new-variant-set')" class="ml-10" color="primary">
                <v-icon small left>mdi-plus</v-icon>
                Add New Variants
              </v-btn>
        </v-card-title>
      </v-col>
    </v-row>
    <v-row>
        <v-col>
          <v-sheet elevation="4">
            <!--Variant data table-->
            <template>
              <div>
                <v-data-table
                    :headers="tableHeaders"
                    :items="variantSets"
                    :search="search"
                    item-key="variant_set_id"
                    show-expand
                    single-expand
                >
                <!-- Edit Button -->
                <template
                    v-slot:item.variant_set_id={item}
                    v-if="this.user_roles_list.flat().includes('products_write')"
                >
                    <v-btn
                        :to="{ name: 'edit-variant-set', params: { variant_set_id: item.variant_set_id }}"
                        color="#3d2cdd"
                        icon
                        small
                    ><v-icon small>mdi-pencil</v-icon></v-btn>
                </template>
                <!-- Variant item expansion list -->
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length" class="px-0">
                  <v-sheet elevation="0" class="py-3">
                    <v-container>
                      <v-row>
                        <v-col md="6" sm="6">
                          <h4>Name</h4>
                        </v-col>
                        <v-col md="6" sm="6">
                            <h4>Code</h4>
                        </v-col>
                      </v-row>
                      <div
                        v-for="variant in item.variants"
                        :key="variant.variant_code"
                      >
                        <v-row class="py-2">
                          <v-col>
                            {{variant.variant_name}}
                          </v-col>
                          <v-col>
                            {{variant.variant_code}}
                          </v-col>
                        </v-row>
                      </div>
                    </v-container>
                  </v-sheet>
                </td>
              </template>
                </v-data-table>
              </div>
            </template>
          </v-sheet>
        </v-col>
      </v-row>
  </v-container>
</template>
<script>
import db from "../../../components/firebaseInit";
import mixin_UserRoles from "../../../globalActions/mixin_UserRoles";

export default {
  name: "Variants",
  mixins: [mixin_UserRoles],
    data(){
        return {
            search: "",
            tableHeaders: [
                { text: 'Edit', sortable: true, value: 'variant_set_id'},
                { text: 'Code', sortable: true, value: 'variant_set_code'},
                { text: 'Name', sortable: true, value: 'variant_set_name'},
                { text: 'Variants', sortable: true, value: 'variants.length'},
            ],
            variantSets: [],
        }
    },
    methods: {
      getVariantSets() {
        const docRef = db.collection("variant_sets").orderBy("variant_set_name");

        docRef.onSnapshot((snapshot) => {
          this.variantSets = [];
          this.variantSets = snapshot.docs.map(doc => doc.data());
        });
      },
    },
    created(){
      this.getVariantSets();
    }
};
</script>
