<template>
    <v-container  fluid>
        <v-row dense>
            <v-col lg="8" offset-lg="2">
                <v-card-title>
                    <v-btn
                        to="/variants"
                        color="#ffffff"
                        light
                        elevation="0"
                    >
                    <v-icon left large>mdi-chevron-left</v-icon>
                    </v-btn>
                    <h3>{{ $route.params.variant_set_id ? 'Edit' : 'New' }} Variant Set</h3>
                </v-card-title>
                <v-card>
                    <v-form @submit.prevent="submitVariants" class="pa-3" ref="form" v-model="valid">
                        <v-container>
                            <v-row dense>
                                <v-col cols="12" sm="6" md="6">
                                    <v-text-field
                                        v-model="variant_set_name"
                                        :rules="[v => !!v || 'A variant set name is required']"
                                        label="Set Name"
                                        outlined
                                        dense
                                        color="#3d2cdd"
                                    />
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                    <v-text-field
                                        v-model="variant_set_code"
                                        label="Variant Set #"
                                        readonly
                                        outlined
                                        dense
                                        color="#3d2cdd"
                                    >
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <!-- Items -->
                            <v-row dense>
                                <v-col cols="12" sm="6" md="6">
                                        <div v-for="(variant, index) in variants" :key="index">
                                            <v-row dense>
                                                <v-col cols="12" sm="6" md="6">
                                                    <v-text-field
                                                        v-model="variant.variant_name"
                                                        label="Name"
                                                        :rules="getVariantRules(variant, index, variants.length)"
                                                        outlined
                                                        dense
                                                        color="#3d2cdd"
                                                    />
                                                </v-col>
                                                <v-col cols="12" sm="6" md="6">
                                                    <v-text-field
                                                        v-model="variant.variant_code"
                                                        label="Code"
                                                        :rules="[v => isUniqueVariantCode(v, index)]"
                                                        outlined
                                                        dense
                                                        color="#3d2cdd"
                                                    />
                                                </v-col>
                                            </v-row>
                                        </div>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            to='/variants'
                            color="#ce2458"
                            text
                            x-large
                        >
                            Cancel
                            <v-icon right>mdi-close</v-icon>
                        </v-btn>
                        <v-btn
                            @click="submitVariants"
                            color="#33cc99"
                            text
                            x-large
                            :disabled="!isSubmitEnabled"
                            :loading="loading"
                        >
                            {{ $route.params.variant_set_id ? 'Update' : 'Submit' }}
                            <v-icon right>mdi-check</v-icon>
                        </v-btn>
                    </v-card-actions>

                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import db from '../../../../components/firebaseInit'
import firebase from 'firebase'
import {showSnackbar} from '../../../../globalActions/index'
//Mixins
import mixin_UserRoles from '../../../../globalActions/mixin_UserRoles'

export default {
    name: "VariantSetForm",
    mixins: [ mixin_UserRoles ],
    data(){
        return {
            valid: false,
            loading: false,
            variant_set_code: null,
            variant_set_name: null,
            variants: [{ variant_name: null, variant_code: null }],
            variant_set_created_by_id: null,
            variant_set_created_by_name: null  
        }
    },
    computed: {
        isSubmitEnabled() {
            // Check the form's validity and ensure every variant except the last one has a non-null name
            if (this.variants.length === 1 ) {
                return this.variants.every(variant => variant.variant_name !== null && variant.variant_name !== ''); 
            } else {
                return this.variants.slice(0, -1).every(variant => variant.variant_name !== null && variant.variant_name !== '');
            }
        }
    },
    methods: {
        async submitVariants () {
            // Form validation
            if(!this.$refs.form.validate()) return;

            this.loading = true;
            try {
                const variantData = {
                    variant_set_code: this.variant_set_code,
                    variant_set_name: this.variant_set_name,
                    // Use slice to exclude the last item from the variants array before mapping
                    variants: this.variants.slice(0, -1),
                    variant_set_created_by_id: this.variant_set_created_by_id,
                    variant_set_created_by_name: this.variant_set_created_by_name,
                };




                if (this.$route.params.variant_set_id) {
                    // Update existing set
                    await db.collection('variant_sets').doc(this.$route.params.variant_set_id).update(variantData);
                    showSnackbar("Variant set updated successfully!");
                } else {
                    // Create new variant set
                    const newDocRef = db.collection('variant_sets').doc();
                    await newDocRef.set({ variant_set_id: newDocRef.id, ...variantData });

                    // Increment count if creating a new variant set
                    const increment = firebase.firestore.FieldValue.increment(1);
                    const counter = db.collection("variant_counter").doc("FdMiO6fWGeFjMwNYdQga");
                    await counter.update({ variant_set_count: increment });

                    showSnackbar("New variant set created successfully!");
                }

                this.$router.push('/variants');

            } catch(error) {
                console.error(error);
            } finally {
                this.loading = false;
            }


        },
        // Get user who created branch
        async getUser() {
            this.variant_set_created_by_id = firebase.auth().currentUser.uid;

            try {
                const querySnapshot = await db.collection("users")
                    .where("uid", "==", this.variant_set_created_by_id)
                    .get();

                this.variant_set_created_by_name = querySnapshot.docs[0].data().displayName;

            } catch (error) {
                console.error("Error fetching user: ", error);
            }
        }, 
        // Get variant set count from database
        getSetCount() {
            this.loading = true;

            const docRef = db.collection("variant_counter").doc("FdMiO6fWGeFjMwNYdQga");
            
            docRef.onSnapshot((snapshot) => {
                const { variant_set_count } = snapshot.data();
                // Format branch_count with leading zeros
                const formattedCount = `${variant_set_count}`.padStart(4, '0');
                this.variant_set_code = `VAR-${formattedCount}`;
                this.loading = false;
            });
        },
        //
        getVariantRules(variant, index, length) {
            // Check if the current item is the last in the array
            const isLastItem = index === length - 1;
            
            // For the last item, exclude the 'required' rule
            if (isLastItem) {
                return [v => this.isUniqueVariantName(v, index)];
            } else {
                // For all other items, include the 'required' rule
                return [
                    v => !!v || 'Name is required',
                    v => this.isUniqueVariantName(v, index)
                ];
            }
        },
        isUniqueVariantName(value) {
            // Check if the name exists more than once in the array
            const nameOccurrences = this.variants.filter(v => v.variant_name === value).length;
            return nameOccurrences === 1 || (nameOccurrences === 0 && value === null) ? true : 'Variant name must be unique.';
        },  
        isUniqueVariantCode(value, index) {
            // Filter out the current variant being checked to avoid marking it as a duplicate of itself
            const uniqueCodes = this.variants.filter((v, idx) => v.variant_code === value && idx !== index).length;
            return uniqueCodes === 0 ? true : 'Variant code must be unique.';
        }, 
        //
        async loadSetData(setId) {
            this.loading = true;
            const docRef = db.collection('variant_sets').doc(setId);
            const doc = await docRef.get();
            if (doc.exists) {
                const data = doc.data();

                this.variant_set_code = data.variant_set_code;
                this.variant_set_name = data.variant_set_name;
                // Add an extra item to variants array to populate the extra form field
                this.variants = [...data.variants, { variant_name: null, variant_code: null }];
            } else {
                console.log("No such document!");
            }
            this.loading = false;
        }, 
    },
    watch: {
        variants: {
            handler(newVal) {
                // Check if the last variant's name is not null or empty
                if (newVal.length && newVal[newVal.length - 1].variant_name) {
                    // Add a new item to the variants array
                    this.variants.push({ variant_name: null, variant_code: null });
                }
            },
            deep: true
        }
    },
    created() {
        this.getUser();
        this.getSetCount();
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            // `vm` is the instance of the incoming component
            if (to.params.variant_set_id) {
                vm.loadSetData(to.params.variant_set_id);
            } else {
                vm.getSetCount(); // Initialize data for creating a new set
            }
        });
    },
}
</script>
